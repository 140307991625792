<template>
  <EntityTable
    :api-url="apiUrl"
    :columns="columns"
    :title="title"
    :edit-page="editPage"
    :delete-url="deleteUrl"
    edit-page-router="admin-user"
  ></EntityTable>
</template>
<script>
import { defineComponent } from "vue";
import EntityTable from "@/components/EntityTable";

export default defineComponent({
  name: "admin-users",
  components: {
    EntityTable,
  },
  data: function () {
    return {
      apiUrl: "admin/users",
      deleteUrl: "api/admin/user/delete/",

      editPage: "/admin-dashboard/user/",
      title: "Пользователи",
      columns: [
        {
          name: "User Id",
          value: "id",
        },
        {
          name: "E-mail",
          value: "email",
        },
      ],
    };
  },

  methods: {},
});
</script>
